"use client";

import Text from "@/lib/components/Text";
import { MenuEntry } from "@/lib/constants/menuEntries";
import { UrlTranslation, Urls, getUrl } from "@/lib/constants/urls";
import { useRegisterNextParams } from "@/lib/hooks/useRegisterNextParams";
import { useTranslation } from "@/lib/i18n/client";
import { LanguageParams } from "@/lib/types/PageProps";
import Link from "next/link";
import { usePathname } from "next/navigation";

type Props = {
    entry: MenuEntry | MenuEntry[];
    transparent: boolean;
} & LanguageParams;

const MenuItem = ({ entry, transparent, lang }: Props) => {
    const { t } = useTranslation(lang, "menu");
    const pathname = usePathname();
    const registerParams = useRegisterNextParams();

    const getLink = (urlTextKey: string, url: string | UrlTranslation, onClick?: () => void) => {
        let fullUrl = getUrl(url, lang);
        const registerUrl = getUrl(Urls.register.index, lang);
        if (urlTextKey === "register" && registerUrl !== pathname) {
            fullUrl = getUrl(url, lang, null, registerParams);
        }

        if (!onClick) {
            return (
                <Link
                    href={fullUrl}
                    className={`block py-8 border-y-4 border-transparent leading-8 ${
                        transparent ? "hover:border-t-white" : "hover:border-t-primary-500 hover:text-primary-500"
                    }`}
                >
                    <Text className="whitespace-nowrap">{t(urlTextKey)}</Text>
                </Link>
            );
        }

        return (
            <Link
                href={fullUrl}
                onClick={() => {
                    onClick?.();
                }}
                className={`block py-8 border-y-4 border-transparent leading-8 ${
                    transparent ? "hover:border-t-white" : "hover:border-t-primary-500 hover:text-primary-500"
                } hover:cursor-pointer`}
            >
                <Text className="whitespace-nowrap">{t(urlTextKey)}</Text>
            </Link>
        );
    };

    if (Array.isArray(entry)) {
        return (
            <div className="flex gap-6">
                {entry.map((e, i) => (
                    <>
                        {i !== 0 && (
                            <Text className="block py-8 border-y-4 border-transparent select-none leading-8">|</Text>
                        )}
                        {getLink(e.key, e.url, e?.onClick)}
                    </>
                ))}
            </div>
        );
    } else {
        return getLink(entry.key, entry.url, entry?.onClick);
    }
};

export default MenuItem;
